import React from 'react'
import Book from '../components/Book'

import Layout from "../components/layout"
import SEO from "../components/seo"

const CurrentBooks = ({data}) => (

    <Layout pageClass=''>
    <SEO title="Current Books" />
        <main className='main'>
            <div className='typography inner'>
                <h1>Current <span className="red">Books</span></h1>
                {data.allMarkdownRemark.edges.map((e,i) => <Book key={i} data={e} />)}
            </div>
        </main> 
    </Layout>

)

export const pageQuery = graphql`
     {
        
        allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___sortOrder]}, limit: 20) {
            edges {
              node {
                frontmatter {               
                  title
                  subtitle
                  isBeingPublished
                  eBookLink
                  anchor
                  author
                  price
                  image {
                    altText
                    bookImage {
                        childImageSharp {
                            fluid (maxWidth: 275, maxHeight: 380, quality: 100, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                  }
                }
                html
             
              }
            }
        }
      
    }
`

export default CurrentBooks