import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { Link } from 'gatsby'


const Book = ({data}) => {
    const {frontmatter, html} = data.node
    return (

       
        <div className="Book">
            <a className="Book-anchor" id={frontmatter.anchor}></a>
            <div className="Book-container">
                <div className="Book-image">
                    <Img 
                        fluid={data?.node?.frontmatter?.image?.bookImage?.childImageSharp.fluid}
                        alt={data.node.frontmatter.image.altText}
                        style={{
                                width:'100%',
                                display:'block'
                            }}              
                        />
                </div>
                <div className="Book-info" >
                    <h2 className="Book-title">
                        {frontmatter.title}
                        {frontmatter.subtitle && ' - '}
                        {frontmatter.subtitle}</h2>
                        <h4 className="Book-author">By {frontmatter.author}</h4>
                    {frontmatter.price ? 
                        <h3 className="Book-price">
                            ${frontmatter.price.toFixed(2)} <span className="incls">(includes P&P)</span>
                        </h3>
                    : ''}
                    
                    
                    <div className="Book-buy">
                        {frontmatter.eBookLink ? <a href={frontmatter.eBookLink} className="Book-eBookLink" rel="noreferrer" target="_blank">eBOOK</a>: ''}

                        {frontmatter.isBeingPublished ? <Link to="/buy" className="Book-buyLink">Buy</Link> :''}

                        {!frontmatter.isBeingPublished && !frontmatter.eBookLink ? 
                            <div className="Book-unavailable">
                                <Link to="/contact-us" className="Book-contactUs">
                                    Contact Us
                                </Link>
                                <p>Currently Unavailable</p>
                            </div>
                    
                            : ''
                        }
                    </div>
                    <div className="Book-description" dangerouslySetInnerHTML={{__html: html}}>
                        
                    </div>
                </div>
            </div>
            
        </div>
       
    )

}

export default Book